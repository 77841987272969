import React from 'react';

import { Grid } from "@mui/material";
import { Banner, SubBanner, GridText } from "./AQuemDestina.js";

import { Footer } from '../../components/Footer/Footer.jsx';
import { Header } from '../../components/Header/Header.jsx';

import Arrow from '../../assets/elemento-banners.png'
import Arrows from '../../assets/elemento-subbanner.png'



export function AQuemDestina() {
   

    return (
      <>
<Header/>

        <Banner>
<img src={Arrow} alt=''/>
<h1>A quem se destina</h1>
        </Banner>

      <SubBanner>
 <h1>Biomassa</h1>
  <img src={Arrows} alt=''/>
  <h2>Grafeno</h2>
        </SubBanner>

        <GridText container>
        <Grid item xs={12} md={12}>
<h1>Estamos nos mais diversos<br/>
nichos de mercado</h1>

<p>
O Brasil de 2020 ao final de 2023 transformou-se em um país de referência na produção de nanoplaquetas de
grafeno e na aplicação do nanomaterial de carbono em diversos setores. Com mais de 40 tipos de produtos
lançados contendo nanoplaquetas de grafeno, o país destaca-se não apenas pela produção, mas também pelo
potencial de impacto em mercados de grande consumo. Um dos gargalos para maior inserção do grafeno no
mercado é o alto custo do material e a necessidade de grandes quantidades do insumo para aplicações nas
indústrias de plásticos, tintas e cimento.<br/><br/>
Atualmente, diversas iniciativas de produção de nanoplaquetas no Brasil têm capacidade para produzir
aproximadamente 10 toneladas por ano, com um custo médio de R$ 7000 por quilograma. No entanto, esse custo
torna-se um desafio para a aplicação da tecnologia em mercados em que o custo final do produto é crucial e
onde o consumo poderia ser feito em grande escala trazendo aos mesmos ganhos técnicos e ambientais, como
por exemplo a indústria de Tintas, Concreto, Asfalto e indústria polimérica.<br/><br/>
Considerando esses desafios, esta iniciativa prevê que a produção de nanoplaquetas a partir da biomassa pode
intensificar o desenvolvimento do mercado, beneficiando diferentes setores industriais que anseiam pela
tecnologia de grafeno, apresentando-se como uma alternativa sustentável e com potencial para reduzir os custos
em até 100 vezes. Isso não apenas viabiliza projetos economicamente inviáveis, mas também abriria portas para a
aplicação da tecnologia em diversas indústrias de grande consumo, possibilitando um aumento significativo no
consumo desse produto que pode chegar a mais de 5000 Ton em 5 anos e posicionando de vez o Brasil como o
maior produtor e consumidor da tecnologia no mundo.<br/><br/>
Além do impacto econômico positivo na redução dos custos de produção de nanoplaquetas de grafeno, podemos
também viabilizar economicamente outras indústrias como a indústria do concreto, por meio do balanço de
carbono sendo que a produção de cimento, elemento-chave da mistura de concreto, gera cerca de 7% das
emissões globais de dióxido de carbono (CO2). A indústria global do concreto anunciou no ano passado a meta
de atingir a neutralidade de carbono até 2050 evitando a liberação de 5 bilhões de toneladas de CO2 nesse
período. A tecnologia desenvolvida neste projeto permitirá ao setor avançar em suas metas de redução de
emissões até 2050.<br/><br/>
Desta forma, as empresas dos setores de produção de compósitos poliméricos, tintas, concreto, asfalto, entre
outros setores, podem ser consideradas como partes interessadas. Além disso, o Ministério do Desenvolvimento,
Indústria, Comércio e Serviços, pode ser considerado como parte interessada no projeto, como a forma prática de
exercer a missão estratégica de nova política industrial (neoindustrialização) “Descarbonização da Indústria,
viabilização da transição energética e bioeconomia”.
</p>
       </Grid>
        </GridText >

      <Footer/>

      </>
    )
  }