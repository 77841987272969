import React from 'react';

import { Grid } from "@mui/material";
import { Banner, SubBanner, GridProdServ } from "./ProdServ.js";

import { Footer } from '../../components/Footer/Footer.jsx';
import { Header } from '../../components/Header/Header.jsx';

import Arrow from '../../assets/elemento-banners.png'
import Arrows from '../../assets/elemento-subbanner.png'
import Elemento1 from '../../assets/elemento-produtos-01.png'
import Elemento2 from '../../assets/elemento-produtos-02.png'
import Elemento3 from '../../assets/elemento-produtos-03.png'
import Elemento4 from '../../assets/elemento-produtos-04.png'



export function ProdServ() {
   

    return (
      <>
<Header/>

        <Banner>
<img src={Arrow} alt=''/>
<h1>Produtos e Serviços</h1>
        </Banner>

      <SubBanner>
 <h1>Biomassa</h1>
  <img src={Arrows} alt=''/>
  <h2>Grafeno</h2>
        </SubBanner>

        <GridProdServ container>
        <Grid className='content' item xs={12} md={12}>
      <div>
      <h1>Comercialização de<br/>
 Nanoplaquetas de Grafeno</h1>
 <p>Contamos com estrutura técnica e comercial, pronta para
dar todo suporte necessário na venda de nanoplaquetas
de grafeno, bem como soluções relacionadas ao
nanomaterial, para diferentes mercados.</p>
      </div>
 <img src={Elemento1} className='spin-left' alt=''/>
       </Grid>
      
       <Grid className='content' item xs={12} md={12}>
       <img src={Elemento2} className='spin-right' alt=''/>
 <div>
 <h1>Projetos de Pesquisa</h1>
 <p>Nossa equipe é composta por um time multidisciplinar
formado por engenheiros, químicos, físicos,
nanotecnológos e cientistas de materiais com grande
know-How, capazes de desenvolver projetos de pesquisa
e soluções inteligentes para qualquer área industrial.</p>
 </div>
       </Grid>
       
       <Grid className='content' item xs={12} md={12}>
      <div>
      <h1>Desenvolvimento de Produtos</h1>
 <p>Desde os mais simples até os mais complexos, estamos
prontos para desenvolver uma variedade de produtos,
utilizando nanoplaquetas de grafeno como solução para
todos os ramos de mercado e indústria.</p>
      </div>
<img src={Elemento3} className='spin-left' alt=''/>
       </Grid>
      
       <Grid className='content' item xs={12} md={12}>
       <img src={Elemento4} className='spin-right' alt=''/>
<div>
<h1>Projetos de Fomento à Inovação</h1>
 <p>Desenvolvemos projetos completos, que vão desde a
pesquisa até sua implementação e concepção, de fomento
à inovação relacionados com as nanoplaquetas de grafeno
e nanotecnologias.</p>
</div>
       </Grid>
        </GridProdServ>



      <Footer/>

      </>
    )
  }