import React, { } from 'react';

import { Routes, Route  } from "react-router-dom";

import { Home } from "../pages/Home/Home.jsx";
import { QuemSomos } from '../pages/QuemSomos/QuemSomos.jsx';
import { Politica } from '../pages/Politica/Politica.jsx';
import { ProdServ } from '../pages/ProdServ/ProdServ.jsx';
import { AQuemDestina } from '../pages/AQuemDestina/AQuemDestina.jsx';
import { Contato } from '../pages/Contato/Contato.jsx';

export function AppRoutes(){


    return(
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/politicaprivacidade" element={<Politica/>} />
            <Route path="/quemsomos" element={<QuemSomos/>} />
            <Route path="/produtoseservicos" element={<ProdServ/>} />
            <Route path="/aquemdestina" element={<AQuemDestina/>} />
            <Route path="/contato" element={<Contato/>} />
        </Routes>
    );

}