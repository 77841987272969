import styled from "styled-components";
import { List, ListItem, ListItemText } from "@mui/material";

export const StyledItemText = styled(ListItemText)`
   p {
    color: ${({ theme }) => theme.COLORS.DARKGREY};
    font-size: 1.4rem;
    font-family: 'Gotham-Bold';
   }

   p:hover {
    color: ${({ theme }) => theme.COLORS.GREEN};
   }
`;



export const StyledListItem = styled(ListItem)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 2rem !important;    
`;

export const StyledList = styled(List)`
  padding: 2rem 2.6rem !important;

  .redes-sociais {
    text-align: center;
  }

`;

export const HeaderContainer = styled.header`
  .header {
    background-color: ${({ theme }) => theme.COLORS.WHITE};
    padding: 2rem 2rem;
   
    .active-link {
     color: ${({ theme }) => theme.COLORS.GREEN};
    }
  
  }

  .nav-container {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
  }

  .redes-sociais {
    right: 0;
    position: absolute;
  }

  .nav-items { 
    right: 20rem;
    margin-right: 0rem;
    display: flex;
    align-items: center;
    position: absolute;

    > ul {
      display: flex;
      justify-content: right;
      align-items: center;

      gap: clamp(3rem, 2.4vw, 8rem);
    
      > li {
        font-size: clamp(1.6rem, 2vw, 2.8rem);
        font-family: 'Gotham-Bold';

        > a {
          color: ${({ theme }) => theme.COLORS.DARKGREY};
          padding-bottom: .2rem;
        }

        > a:hover {
          color: ${({ theme }) => theme.COLORS.GREEN};
        }
      } 
    }
  }


  #shrink-logo {
  transition: all .5s;
  width: 70%;
  }


  @media (max-width: 900px) {
    .header {
      padding: 2rem 0rem;
    }

    .nav-items, .redes-sociais {
      display: none;
    }
  }

`;


export const OutsideLink = styled.a`
 text-decoration: none !important;
`;





