import React from 'react';

import { Grid } from "@mui/material";
import { Banner, SubBanner, GridText, GridPdf } from "./QuemSomos.js";

import { Footer } from '../../components/Footer/Footer.jsx';
import { Header } from '../../components/Header/Header.jsx';

import Arrow from '../../assets/elemento-banners.png'
import Arrows from '../../assets/elemento-subbanner.png'
import Elemento from '../../assets/elemento-quem-somos.png'



export function QuemSomos() {
   

    return (
      <>
<Header/>

        <Banner>
<img src={Arrow} alt=''/>
<h1>Quem Somos</h1>
        </Banner>

      <SubBanner>
 <h1>Biomassa</h1>
  <img src={Arrows} alt=''/>
  <h2>Grafeno</h2>
        </SubBanner>

        <GridText container>
        <Grid className='sideA' item xs={12} md={7}>
<h1>Prazer,<br/>
somos a Biograph</h1>

<p>
Somos uma startup que surgiu a partir de um projeto de
pesquisa, desenvolveu uma patente inovadora que permite
a produção de nanoplaquetas de grafeno e outros
subprodutos a partir de biomassa. Essa iniciativa tem um
potencial revolucionário, pois pode causar um grande
impacto no futuro.
</p>
       </Grid>

       <Grid className='sideB' item xs={12} md={5}>
<img src={Elemento} alt="" />
       </Grid>

       <Grid item xs={12} md={12}>
      <p>
      Nossa iniciativa de produzir nanoplaquetas de grafeno a partir de biomassa pode
impactar significativamente o futuro indo desde a viabilização de projetos de P&D até
o desenvolvimento de produtos de alto valor agregado. Essa tecnologia tem o
potencial de transformar diversos setores industriais, impulsionar a inovação e
promover uma abordagem mais sustentável para a produção de materiais avançados.<br/><br/>

Contamos com uma planta piloto localizada no município de Caxias do Sul, cidade
reconhecida pelo seu ecossistema de produção de Grafeno e produtos derivados,
contando hoje com uma capacidade instalada para processar 260 toneladas de
biomassa, o que resulta em aproximadamente 65 Toneladas de nanoplaquetas de
Grafeno por ano.<br/><br/>

Por se tratar de uma tecnologia 100% interna e patenteada, todo o processo
produtivo foi desenvolvido tendo como objetivo o cuidado com o meio ambiente,
sendo portanto, a Biograph, uma empresa alinhada aos princípios “ESG”.

      </p>
       </Grid>
        </GridText >


        <GridPdf container>
        <Grid item xs={12} md={12}>

          <div className="green-line"></div>
          <h1>
          Ética e Conformidade
          </h1>
      <p>
      Acesse os links abaixo para realizar o download das nossas diretrizes de ética e conformidade.
      </p>

      <div className="botao">
      <a className='button' target='_blank' href="./pdf/Código_de_Ética_e_Guia_de_Conduta_da_Biograph_Brasil.pdf">Código de Ética e Guia de Conduta</a>
      </div>

      <div className="botao">
      <a className='button' target='_blank' href="./pdf/Política_de_Treinamento_e_Comunicação_da_Biograph_Brasil.pdf">Política de Treinamento e Comunicação</a>
      </div>
       </Grid>

     
        </GridPdf>

      <Footer/>

      </>
    )
  }