import React from 'react';
import { Link, useLocation, useNavigate  } from 'react-router-dom';

export function CustomLink({ to, children, ...props }) {
  const location = useLocation();
  const navigate = useNavigate ();
  const isActive = location.pathname === to;

  const handleClick = (e) => {
    if (location.pathname === to) {
      e.preventDefault();      
      const targetElement = document.querySelector('.anchor');
      const offset = 100; // Valor de deslocamento
      if (targetElement) {
        const elementPosition = targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' });
      } else {
        window.scrollTo(0, 0);
      }
    } else {
      navigate(to); // LINK 
      window.scrollTo(0, 0);
    }
  };

  return (
    <Link to={to} className={isActive ? 'active-link' : ''} {...props} onClick={handleClick}>
      {children}
    </Link>
  );
}

export default CustomLink;
