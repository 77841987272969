import styled from "styled-components";

import { TextField } from "@mui/material";

export const FormContainer = styled.div`
   margin: 4rem 0;

   form {
    display: flex;
    flex-direction: column;
    align-items: left;
  
    font-family: 'Gotham-Light'; 

    .mensagem {
      font-size: clamp(1.8rem,  4vw, 2.2rem) !important; 
      margin-top: 1rem;

      small {
        font-size: 1.4rem;
      }
    }


    label {
      color: ${({ theme }) => theme.COLORS.DARKGREY};
    }
   }
`;


export const FieldWrapper = styled.div`
   margin-bottom: 1.4rem;
`;


export const FormField = styled(TextField)`
width: 100%;

.MuiInputLabel-root {
  font-size: clamp(1.8rem,  4vw, 2.2rem) !important; 
  font-family: 'Gotham-Light'; 
};

.MuiInputLabel-root.Mui-focused {
  color: ${({ theme }) => theme.COLORS.DARKGREY};
};

.MuiInputBase-root {
  font-family: 'Gotham-Light'; 
  font-size: 1.6rem;
  color: black !important;
}



.MuiInput-underline {
  &:after {
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.GREEN};
  }

  &.Mui-focused:after {
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.GREEN};
  }

  &:before {
    border-bottom: 1px solid  ${({ theme }) => theme.COLORS.GREEN};
  }

  &:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${({ theme }) => theme.COLORS.GREEN};
  }

 
}
`;


export const TextAreaWrapper = styled.div`
border: 1px solid ${({ theme }) => theme.COLORS.GREEN};
margin-top: .2rem;

&:hover {
  outline: 1px solid ${({ theme }) => theme.COLORS.GREEN};
}

&:focus-within {
  outline: 1px solid ${({ theme }) => theme.COLORS.GREEN};
  }
`;


export const FormTextArea = styled.textarea`
  width: 100%;
  height: 8rem;
  padding: 1rem;

  border: none;
  outline: none;
  resize: none;


  font-family: 'Gotham-Light'; 
  color: black !important;
  font-size: 1.6rem;
`;


export const ButtonWrapper = styled.div`
text-align: center;
`;


export const FormButton =styled.button`
    padding: 1.5rem 16%;
    border: none;
    border-radius: 999px;
    margin-top: 3rem;
    margin-left: auto;
    margin-right: auto;

    background-color:${({ theme }) => theme.COLORS.GREEN};
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: clamp(1.4rem, 2vw, 2.2rem);
    font-family: 'Gotham-Bold'; 
    letter-spacing: 2px;

    transition: all .1s;
    
    &:hover {
        background-color:${({ theme }) => theme.COLORS.DARKGREY};
    }
    
`;