import styled from 'styled-components';

import { Container, Grid } from "@mui/material";
import CustomLink from '../CustomLink/CustomLink';

export const StyledContainer = styled(Container)`
  padding-top: 6rem;
  padding-bottom: 4rem;

  height: 100%;
  background: linear-gradient(to right, #1D9AD4, #48AB79);
  text-align: left;

  @media (max-width: 900px) {
   text-align: center;
  }



`;

export const StyledGrid = styled(Grid)`
padding-right: 2rem;

.logo {
   
    text-align: center;
    img {
        width: clamp(24rem, 23vw, 44rem);
        margin-bottom: 2rem;
    }
}

li {
  margin-bottom: 0.5rem;
}


 h1 {
    color: ${({ theme }) => theme.COLORS.LIGHTBLUE};
    font-family: 'Gotham-Bold'; 
    font-size:  clamp(1.6rem, 2vw, 1.8rem);
    margin-bottom: .8rem;
    margin-top: 2rem;
}

p, span, a {
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-family: 'Gotham-Light';
    font-size:  1.6rem;
}

 span:hover, a:hover {
    color: ${({ theme }) => theme.COLORS.LIGHTBLUE}; 
}


@media (max-width: 900px) {
  padding-right: 0rem;
.logo {
    padding: 0;
}


h1 {
    margin-top: 4rem;
}
  }
 
`;


export const StyledLink = styled(CustomLink)`
 text-decoration: none !important;
`;


export const OutsideLink = styled.a`
 text-decoration: none !important;
`;


export const Line = styled.div`
border-top: 1px solid ${({ theme }) => theme.COLORS.WHITE};
margin-left: auto;
margin-right: auto;

text-align: center;
margin-top: 4rem;

p {
  margin: 2rem 0;
}

width: 65%;

@media (max-width: 600px) {
    width: 100%;
  }
`;


export const ParceirosGrid = styled(Grid)`
  gap: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;




