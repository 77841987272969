import React, { useState } from 'react';

import { Grid } from "@mui/material";
import { Banner, SubBanner, GridMap, Address, ContainerContatos } from "./Contato.js";

import { GoogleMap, LoadScript, InfoWindow, MarkerF } from '@react-google-maps/api';

import { Footer } from '../../components/Footer/Footer.jsx';
import { Header } from '../../components/Header/Header.jsx';
import { Form } from '../../components/Form/Form.jsx';

import Arrow from '../../assets/elemento-banners.png'
import Arrows from '../../assets/elemento-subbanner.png'

import TelIcon from '../../assets/contato-icon-telefone.png'
import EmailIcon from '../../assets/contato-icon-email.png'
import PinIcon from '../../assets/contato-icon-endereco.png'
import WhatsIcon from '../../assets/contato-icon-whats.png'


const containerStyle = {
  width: '100%',
  height: '600px',
};

const address = {
  lat: -23.6882297,
  lng: -46.5637859
};






const apiKey = 'AIzaSyD0yNbu6QkqQSZXgkrthycKFjmvdRf-9jI';

const libraries = ['places'];


export function Contato() {
  const [map, setMap] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const handleWhatsappClick = () => {
    window.open('https://wa.me/5521981975625?text=', '_blank');
  };
  
  const onLoad = React.useCallback((mapInstance) => {
    setMap(mapInstance);
    setIsLoaded(true);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

    return (
      <>
<Header/>

        <Banner>
<img src={Arrow} alt=''/>
<h1>Contato</h1>
        </Banner>

      <SubBanner>
 <h1>Biomassa</h1>
  <img src={Arrows} alt=''/>
  <h2>Grafeno</h2>
        </SubBanner>


        <GridMap container>
        <Grid className="formgrid" item xs={12} md={6}>

<div className='text'>
<h1>Fale com nossa equipe</h1>
<p>Estamos à disposição para tirar todas as suas
dúvidas e levar mais inovação para os seus
projetos.</p>
</div>

<Form pagina={'Biograph'}/>
</Grid>

       <Grid item xs={12} md={6}>
       <LoadScript 
  googleMapsApiKey={apiKey} 
  onLoad={onLoad} 
  onUnmount={onUnmount}
  libraries={libraries} 
>
    <GoogleMap mapContainerStyle={containerStyle} center={address} zoom={15}>
      <MarkerF headerDisabled  headerContent={<h3>InfoWindow Headline</h3>} position={address}>
        <InfoWindow position={address}>
          <Address>
            <h1>BIOGRAPH</h1>
            <p>
            Av. Antártico, 381 - Conj. 25 e 26<br/> 
            Jardim do Mar, São Bernardo <br/>do Campo - SP,
            09726-150
            </p>
          </Address>
        </InfoWindow>
      </MarkerF>
    </GoogleMap>
</LoadScript>
       </Grid>
       </GridMap>


       <ContainerContatos container>
       <Grid className='grid-contatos' item xs={12} md={6}>
        <img src={TelIcon} alt=''/>
       <div className='content'>
          <h1>Telefones</h1>
          <a href="tel:+55114121.4177">(11) 4121-4177</a><br/><br/>
          <a href="tel:+55114121.4201">(11) 4121-4201</a>
        </div>
        </Grid>

      
        <Grid className='grid-contatos' item xs={12} md={6}>
        <img src={EmailIcon} alt=''/>
        <div className='content'>
          <h1>Email</h1>
          <a href="mailto:contato@biographbrasil.com.br">contato@biographbrasil.com.br</a>
        </div>     
        </Grid>

        <Grid className='grid-contatos' item xs={12} md={6}>
        <img className='pin' src={PinIcon} alt=''/>
        <div className='content'>
          <h1>Endereço</h1>
          <p>
          Av. Antártico, 381 – Conj. 25 e 26 - Jardim do Mar, São Bernardo do Campo - SP, 09726-150
          </p>
        </div>
        </Grid>

        <Grid className='grid-contatos' item xs={12} md={6}>
        <img src={WhatsIcon} alt=''/>
        <div className='content'>
          <h1>WhatsApp</h1>
          <span onClick={handleWhatsappClick}>(21) 98197-5625</span>
        </div>
        </Grid>
       </ContainerContatos>

      <Footer/>

      </>
    )
  }