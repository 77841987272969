import styled from "styled-components";

import BannerImg from "../../assets/banner-publico.jpg"

import { Grid } from "@mui/material";


export const Banner = styled.div`
margin-top: 12rem;

background: url(${BannerImg});
background-repeat: no-repeat;
background-position: top center; 
background-size: cover; 
height: 35rem;

display: flex;
align-items: center;
gap: 3rem;

padding: 0 10rem;

img {
  animation: fadeIn 2s ease-in-out;
}

h1 {
  animation: fadeIn 2s ease-in-out;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-family: 'Yagi';
  font-size:  clamp(4.6rem, 8vw, 8rem);
  letter-spacing: 2px;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@media (max-width: 900px) {
  margin-top: 11rem;
  position: relative;
  
  justify-content: center;
  text-align: center;

  img {
    position: absolute;
    left: 3rem;
}

  h1 {
    width: min-content;
  }

}

@media (max-width: 500px) {
  margin-top: 9rem;

  img {
    position: absolute;
    left: 1rem;
}
}

`;

export const SubBanner = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3rem 0;
  gap: 2rem;


  h1, h2 {
  animation: fadeIn 2s ease-in-out;
  font-family: 'Yagi'; 
  font-size:  clamp(6rem, 6vw, 10rem);
  letter-spacing: 2px;
  padding-bottom: 2rem;
}

h1 {
  color: ${({ theme }) => theme.COLORS.GREEN};
}

h2 {
  color: ${({ theme }) => theme.COLORS.DARKGREY};
}

img {
  width:  clamp(20rem, 40vw, 80rem);
  animation: fadeInOut 2.4s ease-in-out infinite;

}

@media (max-width: 900px) {
  display: none;
}


`;


export const GridText = styled(Grid)`
 padding: 0 10rem;
 padding-bottom: 12rem;


h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3.4rem, 4vw, 5.5rem);
  color: ${({ theme }) => theme.COLORS.GREEN};
  letter-spacing: 2px;
  margin-bottom: 2rem;
  }

  p {
    font-family: 'Gotham-Light'; 
  font-size:  clamp(1.8rem, 2.5vw, 2.8rem);
  color: ${({ theme }) => theme.COLORS.DARKGREY};
  letter-spacing: 1px;
  }



  @media (max-width: 900px) {
  text-align: center;
  padding: 0 4rem;
  padding-bottom: 10rem;
  margin-top: 6rem;
}

@media (max-width: 500px) {
    padding: 0 2rem;
    padding-bottom: 10rem;
}
`;




