import React, { useState } from 'react';
import { StyledContainer, StyledGrid, StyledLink, OutsideLink, Line, ParceirosGrid } from './Footer.js';

import FooterLogo from "../../assets/rodape-logo.png"

import LogoFaceOn from '../../assets/rodape-icon-fb-on.png'
import LogoFaceOff from '../../assets/rodape-icon-fb-off.png'

import LogoInstaOn from '../../assets/rodape-icon-ig-on.png'
import LogoInstaOff from '../../assets/rodape-icon-ig-off.png'

import LogoLinkedinOn from '../../assets/rodape-icon-ld-on.png'
import LogoLinkedinOff from '../../assets/rodape-icon-ld-off.png'

import LogoParceiro1 from '../../assets/rodape-parceiros-01.png'
import LogoParceiro2 from '../../assets/rodape-parceiros-02.png'
import LogoParceiro3 from '../../assets/rodape-parceiros-03.png'

import CustomLink from '../CustomLink/CustomLink.jsx';


import { Grid } from '@mui/material';

export function Footer() {
    const [isFacebookHovered, setIsFacebookHovered] = useState(false);
    const [isInstaHovered, setIsInstaHovered] = useState(false);
    const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);


    const handleMouseOverFace = () => {
        setIsFacebookHovered(true);
      };

    const handleMouseOutFace = () => {
        setIsFacebookHovered(false);
      };
      
    
      const handleMouseOverInsta = () => {
        setIsInstaHovered(true);
      };

      const handleMouseOutInsta = () => {
        setIsInstaHovered(false);
      };

      const handleMouseOverLinkedin = () => {
        setIsLinkedinHovered(true);
      };

      const handleMouseOutLinkedin = () => {
        setIsLinkedinHovered(false);
      };

    return (
      <>

<StyledContainer maxWidth="xg">
      <Grid container>
      <StyledGrid item xs={12} sm={12}  md={4} xl={4}>

           <div className='logo'>
           <CustomLink to="/"><img src={FooterLogo} alt="Logo" /></CustomLink>
           </div>
        </StyledGrid>



        <StyledGrid item xs={12} sm={12}  md={2} xl={2}>
        <h1>A BIOGRAPH</h1>
      
      <div>
      <ul>
      <li><StyledLink to="/quemsomos"><span>Quem Somos</span></StyledLink></li> 
      <li><StyledLink to="/produtoseservicos"><span>Produtos e Serviços</span></StyledLink></li>
      <li><StyledLink to="/aquemdestina"><span>A quem se destina</span></StyledLink></li>
      <li><StyledLink to="/contato"><span>Contato</span></StyledLink></li>
     <li><OutsideLink target='_blank' href="https://biographbrasil.com.br/politicaprivacidade">Política de Privacidade</OutsideLink>  </li>
      </ul>   
      </div>
        </StyledGrid>
        



        <StyledGrid item xs={12} sm={12}  md={3} xl={3}>
        <h1>ENDEREÇO</h1>
        <p>
        Av. Antártico, 381 - Conj. 25 e 26 -<br/> Jardim do Mar, São Bernardo do<br/> Campo - SP, 09726-150
        </p>

        <h1>CONTATOS</h1>
         <ul>
            <li><OutsideLink href="tel:+55114121.4177"><span>(11) 4121-4177</span></OutsideLink></li>
            <li><OutsideLink href="tel:+55114121.4201"><span>(11) 4121-4201</span></OutsideLink></li>
            <li><OutsideLink href="mailto:contato@biographbrasil.com.br"><span>contato@biographbrasil.com.br</span></OutsideLink></li> 
         </ul>
        </StyledGrid>

        <StyledGrid item xs={12} sm={12}  md={2} xl={2}>
        <h1>REDES SOCIAIS</h1>
       
       <OutsideLink href="https://www.facebook.com/biographbr" target="_blank" onMouseOver={handleMouseOverFace} onMouseOut={handleMouseOutFace}>
              <img style={{ width: 30 }} src={isFacebookHovered ? LogoFaceOn : LogoFaceOff} border="0" alt=''/>
            </OutsideLink>
      
            <OutsideLink href="https://www.instagram.com/biographbr" target="_blank" onMouseOver={handleMouseOverInsta} onMouseOut={handleMouseOutInsta}>
              <img style={{ width: 30, marginRight:'1.4rem', marginLeft: '1.4rem' }} src={isInstaHovered ? LogoInstaOn : LogoInstaOff} border="0" alt=''/>
            </OutsideLink>
      
            <OutsideLink href="https://linkedin.com/company/biographbr" target="_blank" onMouseOver={handleMouseOverLinkedin} onMouseOut={handleMouseOutLinkedin}>
              <img style={{ width: 30 }} src={isLinkedinHovered ? LogoLinkedinOn : LogoLinkedinOff} border="0" alt=''/>
            </OutsideLink>
        </StyledGrid>

       
      </Grid>

      <Grid container>
      <StyledGrid item xs={12} sm={12}  md={12}>
        <Line> 
     <p>Parceiros</p>
        </Line>
      </StyledGrid>

      <ParceirosGrid item xs={12} sm={12}  md={12}>
      <img src={LogoParceiro1} alt=''/>
      <img src={LogoParceiro2} alt=''/>
      <img src={LogoParceiro3} alt=''/>
      </ParceirosGrid>
      </Grid>


         

</StyledContainer>

     </>
    )
  }