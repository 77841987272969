import styled from "styled-components";

import { Dialog, Typography } from "@mui/material";


export const Title = styled(Typography)`
font-size: clamp(2rem, 2vw, 2.4rem) !important;

font-family: 'Yagi' !important; 
text-align: center;
color:${({ theme }) => theme.COLORS.GREEN} !important;
`;

export const Text = styled(Typography)`
font-size: clamp(1.4rem, 2vw, 1.8rem) !important;
font-family: 'Gotham-Light' !important;
color:${({ theme }) => theme.COLORS.DARKGREY} !important;
`;

export const ModalAviso = styled(Dialog)`
`;