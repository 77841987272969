import styled from "styled-components";

import BannerImg from "../../assets/banner-contato.jpg"

import { Grid } from "@mui/material";

export const Banner = styled.div`
margin-top: 12rem;

background: url(${BannerImg});
background-repeat: no-repeat;
background-position: top center; 
background-size: cover; 
height: 35rem;

display: flex;
align-items: center;
gap: 3rem;

padding: 0 10rem;

img {
  animation: fadeIn 2s ease-in-out;
}

h1 {
  animation: fadeIn 2s ease-in-out;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-family: 'Yagi';
  font-size:  clamp(5rem, 12vw, 8rem);
  letter-spacing: 2px;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (max-width: 900px) {
  margin-top: 11rem;
  position: relative;
  
  justify-content: center;
  text-align: center;

  img {
    position: absolute;
    left: 3rem;
}

  h1 {
    width: min-content;
  }

}

@media (max-width: 500px) {
  margin-top: 9rem;

  img {
    position: absolute;
    left: 1rem;
}
}

`;

export const SubBanner = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3rem 0;
  gap: 2rem;


  h1, h2 {
  animation: fadeIn 2s ease-in-out;
  font-family: 'Yagi'; 
  font-size:  clamp(6rem, 6vw, 10rem);
  letter-spacing: 2px;
  padding-bottom: 2rem;
}

h1 {
  color: ${({ theme }) => theme.COLORS.GREEN};
}

h2 {
  color: ${({ theme }) => theme.COLORS.DARKGREY};
}

img {
  width:  clamp(20rem, 40vw, 80rem);
  animation: fadeInOut 2.4s ease-in-out infinite;
}

@media (max-width: 900px) {
  display: none;
}

`;

export const GridMap = styled(Grid)`
margin-top: 6rem;
align-items: center;

.formgrid {
  padding: 0 6rem;
}

h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3.4rem, 4vw, 5.5rem);
  color: ${({ theme }) => theme.COLORS.GREEN};
  letter-spacing: 2px;
  margin-bottom: 2rem;
  }

  p {
    font-family: 'Gotham-Light'; 
  font-size:  clamp(2.4rem, 2.5vw, 2.8rem);
  color: ${({ theme }) => theme.COLORS.DARKGREY};
  letter-spacing: 1px;
  }


  @media (max-width: 900px) {
    .formgrid {
  padding: 0 3rem;
  margin-top: 2rem;
  margin-bottom: 6rem;

  .text {
    text-align: center;
  }
}
  }

@media (max-width: 500px) {
    .formgrid{
      padding: 0 2rem;

    }
}

`;

export const Address = styled.div`
  font-family: 'Gotham-Bold';   

  h1 {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.COLORS.GREEN};
  }
  
  p {
    font-size: 1.4rem;
    color: ${({ theme }) => theme.COLORS.DARKGREY};
  }
`;


export const ContainerContatos = styled(Grid)`
 color: ${({ theme }) => theme.COLORS.DARKGREY};
 margin-top: 10rem;

 padding: 0 2rem;

 .grid-contatos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 6rem;

  img {
    width: 6rem;
  }

  

  .content {
    width: 44rem;
    padding-right: 2rem;
  }

 }

 h1 {
  font-size: clamp(2rem, 3vw, 4rem);
    font-family: 'Gotham-Bold'; 
    margin-bottom: 2rem;
  }
  
  p, span, a {
    font-size:  clamp(1.6rem, 2.5vw, 2.4rem);
    font-family: 'Gotham-Light';   
    text-decoration: none !important;
    color: ${({ theme }) => theme.COLORS.DARKGREY};
  }

  span {
    cursor: pointer;
  }

  span:hover, a:hover {
    color: ${({ theme }) => theme.COLORS.GREEN};
  }



  @media (max-width: 600px) {
    padding: 0 2rem;

    h1 {
      margin-bottom: 1rem;
    }

    .grid-contatos {

      gap: 1rem;

      img {
      width: 4rem;
    }

    .pin {
    height: 5rem;
  }

    }

    
  }

`;





