import React, { useState } from 'react';
import { HeaderContainer, StyledList, StyledListItem, StyledItemText, OutsideLink } from './Header.js';
import CustomLink from '../CustomLink/CustomLink.jsx';

import MenuIcon from '@mui/icons-material/Menu';

import Logo from '../../assets/cabecalho-logo.png'

import LogoFaceOn from '../../assets/cabecalho-icon-fb-on.png'
import LogoFaceOff from '../../assets/cabecalho-icon-fb-off.png'

import LogoInstaOn from '../../assets/cabecalho-icon-ig-on.png'
import LogoInstaOff from '../../assets/cabecalho-icon-ig-off.png'

import LogoLinkedinOn from '../../assets/cabecalho-icon-ld-on.png'
import LogoLinkedinOff from '../../assets/cabecalho-icon-ld-off.png'

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer, 
  useMediaQuery,
  useTheme,
} from '@mui/material';







export function Header() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);


  const [isFacebookHovered, setIsFacebookHovered] = useState(false);
  const [isInstaHovered, setIsInstaHovered] = useState(false);
  const [isLinkedinHovered, setIsLinkedinHovered] = useState(false);


  const handleMouseOverFace = () => {
      setIsFacebookHovered(true);
    };

  const handleMouseOutFace = () => {
      setIsFacebookHovered(false);
    };
    
  
    const handleMouseOverInsta = () => {
      setIsInstaHovered(true);
    };

    const handleMouseOutInsta = () => {
      setIsInstaHovered(false);
    };

    const handleMouseOverLinkedin = () => {
      setIsLinkedinHovered(true);
    };

    const handleMouseOutLinkedin = () => {
      setIsLinkedinHovered(false);
    };


  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };


  
  function scrollFunction() {
    if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
      document.getElementById("shrink-logo").style.width= "55%";
    } else {
      document.getElementById("shrink-logo").style.width= "70%";
    }
  }
  
  
  window.onscroll = function() {scrollFunction()};

    return (
      <>
      <HeaderContainer >
        <AppBar className='header'>
          <Toolbar>
           <div className="nav-container">
           
           <div className="congresso">
           <CustomLink to="/"><img id="shrink-logo" src={Logo} alt="Logo" /></CustomLink>
           </div>
          
              <div className='nav-items'>
            <ul>
            <li>
            <CustomLink to="/quemsomos">Quem Somos</CustomLink>
            </li>

            <li>
            <CustomLink to="/produtoseservicos">Produtos e Serviços</CustomLink>
            </li>

            <li>
            <CustomLink to="/aquemdestina">A quem se destina</CustomLink>
            </li>

            <li>
            <CustomLink to="/contato">Contato</CustomLink>
            </li>
          </ul>
            </div>

           <div className='redes-sociais'>
           <OutsideLink href="https://www.facebook.com/biographbr" target="_blank" onMouseOver={handleMouseOverFace} onMouseOut={handleMouseOutFace}>
              <img style={{ width: 30 }} src={isFacebookHovered ? LogoFaceOn : LogoFaceOff} border="0" alt=''/>
            </OutsideLink>
      
            <OutsideLink href="https://www.instagram.com/biographbr" target="_blank" onMouseOver={handleMouseOverInsta} onMouseOut={handleMouseOutInsta}>
              <img style={{ width: 30, marginRight:'1.4rem', marginLeft: '1.4rem' }} src={isInstaHovered ? LogoInstaOn : LogoInstaOff} border="0" alt=''/>
            </OutsideLink>
      
            <OutsideLink href="https://linkedin.com/company/biographbr" target="_blank" onMouseOver={handleMouseOverLinkedin} onMouseOut={handleMouseOutLinkedin}>
              <img style={{ width: 30 }} src={isLinkedinHovered ? LogoLinkedinOn : LogoLinkedinOff} border="0" alt=''/>
            </OutsideLink>
           </div>
           </div>
            
            <div style={{ flex: 1 }} /> 
            {isMobile && (
              <IconButton  size='small' color="black" edge="end" onClick={handleDrawerOpen}>
                <MenuIcon style={{ width: '3rem', height: '3rem'}}/>
              </IconButton>
            )}
          </Toolbar>
        </AppBar>

        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose} >
          <StyledList>
    
            <CustomLink to="/quemsomos">
            <StyledListItem disableGutters={true} button onClick={handleDrawerClose}><StyledItemText ><p>Quem Somos</p></StyledItemText></StyledListItem>
            </CustomLink>
            <CustomLink to="/produtoseservicos">
            <StyledListItem button onClick={handleDrawerClose}><StyledItemText ><p>Produto e Serviços</p></StyledItemText></StyledListItem>
            </CustomLink>
            <CustomLink to="/aquemdestina">
            <StyledListItem button onClick={handleDrawerClose}><StyledItemText ><p>A quem se destina</p></StyledItemText></StyledListItem>
            </CustomLink>
            <CustomLink to="/contato">
            <StyledListItem button onClick={handleDrawerClose}><StyledItemText ><p>Contato</p></StyledItemText></StyledListItem>
            </CustomLink>

            <div className='redes-sociais'>
            <OutsideLink href="https://www.facebook.com/biographbr" target="_blank" onMouseOver={handleMouseOverFace} onMouseOut={handleMouseOutFace}>
              <img style={{ width: 30 }} src={isFacebookHovered ? LogoFaceOn : LogoFaceOff} border="0" alt=''/>
            </OutsideLink>
      
            <OutsideLink href="https://www.instagram.com/biographbr" target="_blank" onMouseOver={handleMouseOverInsta} onMouseOut={handleMouseOutInsta}>
              <img style={{ width: 30, marginRight:'1.4rem', marginLeft: '1.4rem' }} src={isInstaHovered ? LogoInstaOn : LogoInstaOff} border="0" alt=''/>
            </OutsideLink>
      
            <OutsideLink href="https://linkedin.com/company/biographbr" target="_blank" onMouseOver={handleMouseOverLinkedin} onMouseOut={handleMouseOutLinkedin}>
              <img style={{ width: 30 }} src={isLinkedinHovered ? LogoLinkedinOn : LogoLinkedinOff} border="0" alt=''/>
            </OutsideLink>
           </div>
           
          </StyledList>
        </Drawer>
      </HeaderContainer>

      
     
    
     </>
    )
  }