import styled from "styled-components";

import BannerImg from "../../assets/banner-produtos-servicos.jpg"

import { Grid } from "@mui/material";

export const Banner = styled.div`
margin-top: 12rem;

background: url(${BannerImg});
background-repeat: no-repeat;
background-position: top center; 
background-size: cover; 
height: 35rem;

display: flex;
align-items: center;
gap: 3rem;

padding: 0 10rem;

img {
  animation: fadeIn 2s ease-in-out;
}

h1 {
  animation: fadeIn 2s ease-in-out;
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-family: 'Yagi';
  font-size:  clamp(5rem, 12vw, 8rem);
  letter-spacing: 2px;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@media (max-width: 900px) {
  margin-top: 11rem;
  position: relative;
  
  justify-content: center;
  text-align: center;

  img {
    position: absolute;
    left: 3rem;
}

  h1 {
    width: min-content;
  }

}

@media (max-width: 500px) {
  margin-top: 9rem;

  img {
    position: absolute;
    left: 1rem;
}
}

`;

export const SubBanner = styled.div` 
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 3rem 0;
  gap: 2rem;


  h1, h2 {
  animation: fadeIn 2s ease-in-out;
  font-family: 'Yagi'; 
  font-size:  clamp(6rem, 6vw, 10rem);
  letter-spacing: 2px;
  padding-bottom: 2rem;
}

h1 {
  color: ${({ theme }) => theme.COLORS.GREEN};
}

h2 {
  color: ${({ theme }) => theme.COLORS.DARKGREY};
}

img {
  width:  clamp(20rem, 40vw, 80rem);
  animation: fadeInOut 2.4s ease-in-out infinite;

}

@media (max-width: 900px) {
  display: none;
}

`;


export const GridProdServ = styled(Grid)`
padding-bottom: 4rem;

img {
  width: clamp( 30rem, 35vw, 50rem);
  animation: spin 6s ease-in-out infinite;
}

/*
.spin-left {
  animation: spin 6s ease-in-out infinite;
}

.spin-right {
  animation: spin-reverse 6s ease-in-out infinite;
}

*/

@keyframes spin {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

/*
@keyframes spin-reverse {
  from {
    transform: rotateY(360deg);
  }
  to {
    transform: rotateY(0deg);
  }
}
*/

.content {
  display: flex;
  align-items: center;
  padding: 0 10rem;
  margin-bottom: 8rem;
  gap: 4rem;

  h1 {
    font-family: 'Yagi'; 
  font-size:  clamp(3.4rem, 4vw, 5.5rem);
  color: ${({ theme }) => theme.COLORS.GREEN};
  letter-spacing: 2px;
  margin-bottom: 2rem;
  }

  p {
    font-family: 'Gotham-Light'; 
  font-size:  clamp(2.4rem, 2.5vw, 3.4rem);
  color: ${({ theme }) => theme.COLORS.DARKGREY};
  letter-spacing: 1px;
  }
}


@media (max-width: 900px) {
  text-align: center;

  img {
    display: none;
  }

  .content {
    padding: 0 4rem;
    margin-bottom: 0;
    margin-top: 6rem;
  }
}

@media (max-width: 500px) {
  .content {
    padding: 0 2rem;
  }
}
`;




