import React, { useState, useEffect  } from 'react';

import { Grid } from "@mui/material";
import { MainGrid, VideoGrid, GridInformativo1, GridValor, GridFolha1, GridInformativo2, GridFolha2 } from "./Home.js";

import { Footer } from '../../components/Footer/Footer.jsx';
import { Header } from '../../components/Header/Header.jsx';

import ReactPlayer from 'react-player/youtube';
import CustomLink from '../../components/CustomLink/CustomLink.jsx';



import ArrowsMain from '../../assets/banner-elemento.png'
import Arrows from '../../assets/degrade-elemento.png'
import Arrow from '../../assets/elemento-banners.png'
import Folha1 from '../../assets/elemento-folha-biomassa.png'
import Folha2 from '../../assets/elemento-folha-sustentabilidade.png'


export function Home() {
  const [valor, setValor] = useState(7000);

  useEffect(() => {
    const counter = document.querySelector(".counter");

    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            //console.log("NA TELA");

            const interval = setInterval(() => {
              setValor(prev => {
                if (prev > 5) {
                  return prev - 10;
                } else {
                  clearInterval(interval);
                  return 5;
                }
              });
            }, 6);

            observer.disconnect();
          } else {
            //console.log("NÃO NA TELA");
          }
        });
      },
      {
        threshold: 1, 
      }
    );

    if (counter) {
      observer.observe(counter);
    }

    return () => observer.disconnect();
  }, []);



    return (
      <>
<Header/>

        <MainGrid container>
<Grid className='top' item xs={12} md={12}>
  <h1>Biomassa</h1>
<img className='arrows' src={ArrowsMain} alt=''/>
<img className='arrow' src={Arrow} alt=''/>
<img className='arrow' src={Arrow} alt=''/>
<img className='arrow' src={Arrow} alt=''/>
   <h1>Grafeno</h1>
</Grid>

<Grid className='bottom' item xs={12} md={12}>
  <p>
  Solução inovadora capaz de transformar<br/>
   <span>biomassa</span> em produtos de <span>alto<br/>
  valor com grafeno</span>
  </p>
</Grid>
        </MainGrid> 
      

<VideoGrid container>
  <Grid className='video' item xs={12} md={7}>
  <ReactPlayer
        url="https://youtu.be/rJhk7vukUZM"
        controls
        className='react-player'
        playsinline
      />
  </Grid>

  <Grid className='texto' item xs={12} md={5}>
    <div className="line"/>

    <h1>Nanotecnologia<br/> Sustentável</h1>

    <p>
    Somos uma startup que surgiu a partir de um projeto de
pesquisa inovador que permite a produção de nanoplaquetas
de grafeno e outros subprodutos a partir de biomassa.<br/><br/>
Essa iniciativa tem um potencial revolucionário que irá causar
um grande impacto no futuro de forma sustentável.
    </p>

    <CustomLink to="/contato"><span>Fale com a gente</span></CustomLink>

</Grid>
</VideoGrid>

<GridInformativo1 container>
  <h1>Transformamos<br/>
  Biomassa em Grafeno</h1>

  <p>
  A Biograph é uma startup que surgiu a partir de um projeto de pesquisa, desenvolveu uma patente inovadora que
permite a produção de nanoplaquetas de grafeno e outros subprodutos a partir de biomassa. Essa iniciativa tem
um potencial revolucionário, pois pode causar um grande impacto no futuro.<br/><br/>
Produzimos nanoplacas de grafeno utilizando resíduos de biomassa como matéria-prima. A fabricação de
nanoplaquetas de grafeno por esta rota é capaz de diminui o custo do grafeno de R$7000/Kg, preço do grafeno
quando obtido usando a metodologia tradicional de esfoliação química ou mecânica do grafite, para R$5,00/Kg.<br/><br/>
Com nossa abordagem exclusiva é possível realizar o total aproveitamento da biomassa que é tratada como
rejeito industrial gerando em seu processo subprodutos de alto valor agregado além da possibilidade de geração
de “syngas” e biocombustíveis no processo por meio da captura e conversão do CO2 liberado durante a pirólise
da biomassa. Essa cadeia produtiva irá gerar valor a resíduos de biomassa que muitas vezes são problemas
ambientais para descarte ou armazenamento.<br/><br/>
Em resumo, a iniciativa da Biograph de produzir nanoplaquetas de grafeno a partir de biomassa pode impactar
significativamente o futuro Desde a viabilização de projetos até o desenvolvimento de produtos de alto valor
agregado, essa tecnologia tem o potencial de transformar diversos setores, impulsionar a inovação e promover
uma abordagem mais sustentável para a produção de materiais avançados.
  </p>
</GridInformativo1>

<GridValor container>

<div className="flechas">
<img src={Arrows} alt="" />
</div>

<Grid  className='valores left' item xs={12} md={6}>
<div className='texto-menor'>
  <h3>de</h3>
  <h2>R$</h2>
</div>
<h1>7000</h1>
</Grid>

<Grid className='valores right' item xs={12} md={6}>
<div className='texto-menor'>
  <h3>por</h3>
  <h2>R$</h2>
</div>
<h1 className='counter'>{valor}</h1>
</Grid>

<Grid className='texto' item xs={12} md={12}>
<p>
Essa é a <span>redução</span> que propomos no <span>preço do quilo</span><br/>
<span>do Grafeno</span> com a produção baseada na <span>biomassa</span>
</p>
</Grid>
</GridValor>


<GridFolha1 container>
  <Grid className='folha' item xs={12} md={6} xl={4}>
<img src={Folha1} alt="" />
  </Grid>

  <Grid className='texto' item xs={12} md={6} xl={8}>
<h1>Como funciona?</h1>
<p>
Nosso processo exclusivo e patenteado pode ser utilizado em todo o tipo de
biomassa que contenha um teor de lignina de aproximadamente de 20%,
(casca de arroz, caroço do açaí, bagaço de cana, celulose, etc) fazendo com
que um problema ambiental se transforme num gerador de produto de alta
qualidade e alto valor agregado.<br/><br/>
Além da produção das nanoplaquetas de grafeno de alta qualidade, ecológicas
e com preço reduzido, a “holding” Biograph e suas empresas subsidiárias
contemplam a fabricação e comercialização de diversos produtos de valor
agregado tais como: gás de síntese ou “syngas” a ser utilizado para geração de
energia renovável, aditivo para concreto, plásticos, asfalto e tintas, substratos
para biofertilizantes, biocombustíveis e biopolímeros.
</p>
</Grid>
</GridFolha1>


<GridInformativo2 container>
  <h1>A primeira Planta de Produção em escala
  industrial de Nanoplaquetas a partir da Biomassa</h1>
  <p>
  A estrutura da Biograph localizada em Caxias do Sul, cidade reconhecida pelo seu ecossistema de produção de
Grafeno e produtos derivados, conta hoje com uma capacidade instalada para processar 260 toneladas de
biomassa, o que resulta em aproximadamente 65 Toneladas de nanoplaquetas de Grafeno por ano.<br/><br/>
Por se tratar de uma tecnologia 100% interna e patenteada, todo o processo produtivo foi desenvolvido tento
como objetivo o cuidado com o meio ambiente, assim, toda a produção é feita com energias renováveis, o insumo
principal do processo é Biomassa, todos os insumos secundários são reaproveitados e 100 da produção é
transformada em produto de valor acrescentado.
  </p>
</GridInformativo2>

    
<GridFolha2 container>
  <Grid className='texto' item xs={12} md={6} xl={8}>
<h1>Mais Sustentabilidade</h1>
<p>
Além de todos os benefícios da produção de nanoplaquetas de grafeno a partir de
biomassa, nosso processo também traz benefícios do ponto de vista ambiental ao
aproveitar resíduos de biomassa.<br/><br/>
Contribuímos para a redução do descarte inadequado de materiais e para a
diminuição da dependência de fontes não renováveis. Isso significa uma pegada de
carbono reduzida e uma abordagem mais sustentável para a produção de materiais
avançados.<br/><br/>
Além do impacto econômico positivo na redução dos custos de produção de
nanoplaquetas de grafeno, podemos também viabilizar economicamente outras
indústrias como a indústria do concreto, por meio do balanço de carbono sendo
que a produção de cimento, elemento-chave da mistura de concreto, gera cerca de
7% das emissões globais de dióxido de carbono (CO2).<br/><br/>
A Biograph tem como premissa a utilização de bioresíduos como forma de
minimizar seu descarte e agregar valor por meio da transformação destes em
nanomateriais avançados com aplicações industriais importantes. 
</p>
</Grid>

<Grid className='folha' item xs={12} md={6} xl={4}>
<img src={Folha2} alt="" />
  </Grid>
</GridFolha2>




      <Footer/>

      </>
    )
  }