import styled from "styled-components";

import Banner from "../../assets/banner-fundo.jpg"

import { Grid } from "@mui/material";


export const MainGrid = styled(Grid)`
height: 100dvh;

background: url(${Banner});
background-repeat: no-repeat;
background-size: cover;

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.top {
padding-top: 38rem;

display: flex;
justify-content: center;
align-items: center;
gap: 2rem;

h1 {
  animation: fadeIn 2s ease-in-out;
  font-family: 'Yagi'; 
  font-size:  clamp(5rem, 6vw, 10rem);
  letter-spacing: 4px;
  color: ${({ theme }) => theme.COLORS.WHITE};
  padding-bottom: 2rem;
}


img {
  width: clamp(20rem, 40vw, 60rem);
  animation: fadeInOut 2.4s ease-in-out infinite;
}

.arrow {
  display: none;
}


}

.bottom {
  animation: fadeIn 2s ease-in-out;
  display: flex;
  justify-content: center;
  text-align: center;
  
  color: ${({ theme }) => theme.COLORS.WHITE};
  font-family: 'Gotham-Bold'; 

  p, span {
    font-size:  clamp(2.6rem, 6vw, 4.4rem);
    letter-spacing: 1px;
}

 span {
  color: ${({ theme }) => theme.COLORS.LIGHTBLUE};
 }


}



@media (max-width: 1400px) {
  .top {
    padding-top: 20rem;
  }
}


@media (max-width: 900px) {
  padding: 0 2rem;
  .top {
     flex-direction: column;
     gap: 0;

     h1 {
      margin-bottom: 2rem;
     }

    .arrow {
      display: inherit;
      transform: rotate(90deg);
      width: 2.8rem;

      margin-top: -4rem;
    }

    .arrows {
      display: none;
    }
  }
}


@media (max-width: 500px) {
  .top {
    padding-top: 14rem;
  }
}

`;

export const VideoGrid = styled(Grid)`
padding-top: 8rem;
padding-bottom: 4rem;


.video {
  padding: 0 4rem;

  .react-player {
    width: 100% !important;
    height: 100% !important ;
    //height: clamp(23rem, 56vw, 80rem) !important;
}
}


.texto {
  padding-right: 10rem;

  .line {
  background: linear-gradient(to right, #1D9AD4, #48AB79);
  height: 1rem;
  width: 20rem;
  margin-bottom: 2rem;
}

p {
  font-family: 'Gotham-Light'; 
  font-size:  clamp(1.8rem, 2.5vw, 2.6rem);
  color:${({ theme }) => theme.COLORS.DARKGREY};
  margin-bottom: 5rem;
  margin-top: 4rem;
}

h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3rem, 6vw, 6rem);
  color:${({ theme }) => theme.COLORS.GREEN};
}

span {
    padding: 1.5rem 4%;
    border-radius: 999px;

    background-color:${({ theme }) => theme.COLORS.GREEN};
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-size: clamp(1.4rem, 2vw, 3rem);
    font-family: 'Gotham-Bold'; 
    letter-spacing: 1px;

    transition: all .1s;
    
    &:hover {
        background-color:${({ theme }) => theme.COLORS.DARKGREY};
    }
};

}


@media (max-width: 900px) {
  padding-top: 5rem;

.video {
  padding: 0 1rem;

  .react-player {
    width: 100% !important;
    //height: 100% !important ;
    height: clamp(23rem, 56vw, 80rem) !important;
}
}

.texto {
  margin-top: 5rem;


  .line {
    margin-left: auto;
    margin-right: auto;
  }


  padding: 0 2rem;
  text-align: center;
}
}

`;


export const GridInformativo1 = styled(Grid)`
padding: 0 10rem;
padding-bottom: 4rem;

p {
  font-family: 'Gotham-Light'; 
  font-size:  clamp(1.8rem, 2.5vw, 2.8rem);
  color:${({ theme }) => theme.COLORS.DARKGREY};
  margin-bottom: 5rem;
  margin-top: 4rem;
}

h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3rem, 6vw, 6rem);
  color:${({ theme }) => theme.COLORS.GREEN};
}


@media (max-width: 900px) {
  display: flex;
  text-align: center;
  padding: 8rem 2rem;
  justify-content: center;
}
`;

export const GridValor = styled(Grid)`
background: linear-gradient(to right, #1D9AD4, #48AB79);
position: relative;

.flechas {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0 !important;

  img {
    width: clamp(40rem, 80vw, 140rem);
    animation: fadeInOut 2.4s ease-in-out infinite;
  }


}

.valores {
  z-index: 1;
  display: flex;
  padding-top: 2rem;
  margin-top: 8rem;
}

.left {
    justify-content: right;
  }

.right {
  padding-left: 12rem;
  }

  .texto-menor {
    padding-top: 5rem;
  }


  .texto {
   text-align: center;
   padding-top: 10rem;
   padding-bottom: 10rem;
 }


h1, h2, h3 {
  font-family: 'Yagi'; 
  letter-spacing: 6px;
}


h1 {
  font-size:  clamp(9rem, 14vw, 22rem);
  color:${({ theme }) => theme.COLORS.WHITE};
}

h2 {
  font-size:  clamp(6rem, 6vw, 10rem);
  color:${({ theme }) => theme.COLORS.WHITE};
}

h3 {
  font-size:  clamp(3rem, 6vw, 5rem);
  color:${({ theme }) => theme.COLORS.LIGHTBLUE};
}


  p, span {
    font-size:  clamp(2.6rem, 3vw, 3.8rem);
    color: ${({ theme }) => theme.COLORS.WHITE};
    font-family: 'Gotham-Bold'; 
    letter-spacing: 1px;
}

 span {
  color: ${({ theme }) => theme.COLORS.LIGHTBLUE};
 }


 @media (max-width: 1300px) {

  .right {
  padding-left: 4rem;
  }
 }


 @media (max-width: 1200px) {
.texto-menor {
  padding-top: 2rem;
}
 }


 @media (max-width: 900px) {
padding-top: 4rem;
padding-left: 2rem;
padding-right: 2rem;

   .flechas {
    display: none;

   }


   .valores {
  z-index: 1;
  display: flex;
  padding-top: 2rem;
  margin-top: 0;
}

.left {
    justify-content: center;
  }

.right {
  padding-left: 0;
  justify-content: center;
  }

  .texto-menor {
    padding-top: 2rem;
  }

  .texto {
    padding-top: 4rem;
  }
 }


`;



export const GridFolha1 = styled(Grid)`
padding-right: 10rem;
padding-bottom: 8rem;
padding-top: 8rem;
position: relative;

img {
  width: clamp(30rem, 40vw, 45rem);
  position: absolute;
  bottom: 0;
  
}

p {
  font-family: 'Gotham-Light'; 
  font-size:  clamp(1.8rem, 2.5vw, 2.8rem);
  color:${({ theme }) => theme.COLORS.DARKGREY};
  margin-top: 4rem;
}

h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3rem, 6vw, 6rem);
  color:${({ theme }) => theme.COLORS.GREEN};
}


@media (max-width: 900px) {
  .folha {
    display: none;
  }

  display: flex;
  text-align: center;
  padding: 8rem 2rem;
  justify-content: center;
}

`;



export const GridInformativo2 = styled(Grid)`
padding: 8rem 10rem;
background-color:${({ theme }) => theme.COLORS.DARKGREY};

p {
  font-family: 'Gotham-Light'; 
  font-size:  clamp(1.8rem, 2.5vw, 2.8rem);
  color:${({ theme }) => theme.COLORS.WHITE};
  margin-top: 4rem;
}

h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3rem, 6vw, 6rem);
  color:${({ theme }) => theme.COLORS.LIGHTGREEN};
  max-width: 128rem ;
}


@media (max-width: 900px) {
  display: flex;
  text-align: center;
  padding: 8rem 2rem;
  justify-content: center;
}
`;



export const GridFolha2 = styled(Grid)`
padding-left: 10rem;
padding-top: 8rem;
padding-bottom: 8rem;
position: relative;

img {
  width: clamp(30rem, 40vw, 45rem);
  position: absolute;
  top: 0;
  right: 1rem;
  
}

p {
  font-family: 'Gotham-Light'; 
  font-size:  clamp(1.8rem, 2.5vw, 2.8rem);
  color:${({ theme }) => theme.COLORS.DARKGREY};
  margin-top: 4rem;
}

h1 {
  font-family: 'Yagi'; 
  font-size:  clamp(3rem, 6vw, 6rem);
  color:${({ theme }) => theme.COLORS.GREEN};
}


@media (max-width: 900px) {
  .folha {
    display: none;
  }

  display: flex;
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
  justify-content: center;
}

`;



