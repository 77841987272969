import { createGlobalStyle } from 'styled-components';
export default createGlobalStyle`


@font-face {
  font-family: 'Yagi'; 
  src: url('./fonts/YagiUhfNo2.otf');
}

@font-face {
  font-family: 'Gotham-Bold'; 
  src: url('./fonts/Gotham-Bold-Regular.ttf');
}

@font-face {
  font-family: 'Gotham-Light'; 
  src: url('./fonts/Gotham-Light.otf');
}

@font-face {
  font-family: 'Gotham-Thin'; 
  src: url('./fonts/Gotham-Thin.otf');
}

@font-face {
  font-family: 'Gotham-Ultra'; 
  src: url('./fonts/Gotham-Ultra.otf');
}

@font-face {
  font-family: 'Gotham-XLight'; 
  src: url('./fonts/Gotham-XLight.otf');
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 62.5%;

    ::-webkit-scrollbar {
	width: 10px;
}

::-webkit-scrollbar-button:start:decrement,
::-webkit-scrollbar-button:end:increment  {
	display: none;
}

::-webkit-scrollbar-track-piece  {
	background-color: #c2c9cb; 
}

::-webkit-scrollbar-thumb:vertical {
	background-color: #285776; 
  border-radius: 1rem;
}

}

html {
  scroll-behavior: smooth;
}


body {
    font-size: 1.6rem;
}

a, button {  
    text-decoration: none;
    cursor: pointer;
}


ul {
   list-style-type: none;
}
`;